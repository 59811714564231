.home-cntr {
  width: 100%;
}

.taste-home {
  width: 100%;
}

.about-cntr {
  display: grid;
  place-items: center;
  margin: 2rem;
}

.about-cntr h1 {
  font-size: 3rem;
  font-family: BreakfastDistressed;
  font-weight: 300;
  color: #f9f9f9;
  border-bottom: 5px solid #ffdf08;
}

.about-cntr p {
  color: #f9f9f9;
  font-size: 1.3rem;
  margin-top: 2rem;
  text-align: center;
  line-height: 1.5;
}

.menu-home {
  width: 100%;
  margin: 2rem 0;
  cursor: pointer;
}

.g-rev-cntr {
  position: fixed;
  bottom: 3rem;
  right: 1rem;
  width: 75px;
  padding: 0.25rem;
  background-color: white;
  border-radius: 50%;
  display: grid;
  place-items: center;
  box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.362);
}

.g-rev-cntr img {
  width: 100%;
  height: auto;
}

@media only screen and (max-width: 768px) {
  .about-cntr {
    margin: 1rem;
  }

  .about-cntr h1 {
    font-size: 2rem;
  }

  .about-cntr p {
    font-size: 1rem;
    margin-top: 1rem;
  }
}
