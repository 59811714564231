.nav-main-cntr {
  display: grid;
  place-items: center;
}

.nav-cntr {
  width: 90%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.menu-hamburger {
  display: none;
}

.resp-menu {
  display: none;
}

.nav-list {
  width: 100%;
  margin: 0 1rem;
}

.nav-list ul {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  list-style: none;
}

.nav-list ul li {
  width: 100%;
  text-align: center;
  position: relative;
}

.nav-left li {
  display: flex;
  justify-content: left;
  align-items: center;
}

.nav-right li {
  display: flex;
  justify-content: right;
  align-items: center;
}

.nav-list li a,
#services,
.contact-cta {
  width: fit-content;
  font-size: 1.2rem;
  font-weight: 500;
  text-decoration: none;
  color: #d6d5d1;
}

#services,
.contact-cta {
  cursor: pointer;
}

.nav-list li p,
.contact-cta p {
  padding-bottom: 0.25rem;
  border-bottom: 2px solid transparent;
  transition: all 0.2s ease-in-out;
}

.active-nav {
  border-bottom: 2px solid #ffdf08 !important;
}

.nav-cta {
  margin-bottom: 1rem;
}

.nav-cta img {
  width: 20px;
  cursor: pointer;
}

.nav-cta-l {
  display: flex;
  gap: 2rem;
  align-items: center;
}

.nav-cta-r {
  display: flex;
  justify-content: right;
  gap: 2rem;
  align-items: center;
  min-height: 20px;
}

.nav-logo {
  height: 150px;
  width: 368px;
  display: grid;
  place-items: center;
  padding: 1rem;
  cursor: pointer;
  position: relative;
}

.logo-bg {
  z-index: -1;
  height: calc(100% + 20px) !important;
  animation: none !important;
  transform: translate(-50%, -25%);
}

.nav-logo img {
  height: 140px;
  width: auto;
  position: absolute;
  top: 25%;
  left: 50%;
  transform: translate(-50%, -25%) rotate(0deg);
}

.services-cntr {
  background-color: #f9f9f9;
  position: absolute;
  left: 30%;
  top: 110%;
  z-index: 1;
  min-width: 160px;
  border-radius: 5px;
  padding: 0.5rem 0;
  box-shadow: 1px 5px 5px 5px rgba(0, 0, 0, 0.33);
  animation: openServices 0.25s forwards ease-in;
}

@keyframes openServices {
  0% {
    opacity: 0;
    transform: scaleY(0.3);
    transform-origin: top center;
  }

  100% {
    opacity: 1;
    transform: scaleY(1);
  }
}

.services-cntr ul {
  flex-direction: column;
}

.services-cntr li {
  display: block;
  padding: 0.5rem;
  text-align: left !important;
  cursor: pointer;
  font-size: 0.9rem;
  transition: all 0.25s ease-in-out;
}

.services-cntr li:hover {
  background-color: #ffdf08;
  color: #142432 !important;
}

@media only screen and (max-width: 768px) {
  .menu-hamburger {
    display: block;
    width: 50px;
    fill: white;
    position: fixed;
    top: 1rem;
    left: 1rem;
    z-index: 1;
  }

  .nav-list {
    display: none;
  }

  .nav-logo {
    padding-bottom: 0rem;
    height: 100px;
    width: 100%;
  }

  .nav-logo img {
    height: 80px;
    left: 50%;
    top: 16%;
  }

  .logo-bg {
    height: 100% !important;
  }

  .resp-menu {
    display: block;
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: -100%;
    z-index: 2;
    animation: openRespMenu 0.5s forwards ease-in-out;
    backdrop-filter: blur(5px);
  }

  @keyframes openRespMenu {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
      left: 0;
    }
  }

  .resp-menu-cntr {
    background-color: white;
    width: 80%;
    height: 100vh;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .close-resp-menu {
    width: 50px;
    height: 50px;
    fill: #142432;
  }

  .resp-menu ul {
    list-style: none;
    max-height: 50vh;
    overflow-y: scroll;
  }

  .resp-menu li {
    padding: 1.5rem 1rem;
    transition: all 0.25s ease-in;
  }

  .resp-menu p {
    text-decoration: none;
    color: #142432;
    font-size: 1.2rem;
    font-weight: 500;
    display: flex;
    justify-content: space-between;
  }

  .resp-menu p span {
    color: #223a4fce;
    font-size: 1.05rem;
  }

  .active-nav {
    background-color: #ffdf08;
    color: #142432 !important;
  }

  .active-nav p {
    color: #142432 !important;
    font-weight: 600;
  }

  .resp-nav-logo {
    display: grid;
    place-items: center;
    width: 100%;
  }

  .resp-nav-logo img {
    width: 200px;
  }

  .services-cntr {
    display: none;
  }

  .resp-serv-menu li {
    padding: 1rem;
  }

  .resp-serv-menu li::before {
    content: "➤";
    margin-right: 0.75rem;
    color: #1424325e;
  }
}
