.menu-header {
  width: 100%;
}

.menu-cntr {
  display: flex;
  min-height: 250px;
  padding: 2rem 0;
  margin: 2rem;
  position: relative;
}

.resp-menu-img {
  display: none;
}

.menu-title {
  flex: 50%;
  position: relative;
  display: flex;
  align-items: center;
}

.resp-title {
  display: none;
}

.menu-title h1 {
  font-size: 2.4rem;
  font-weight: 400;
  color: #ffdf08;
  text-transform: uppercase;
}

.menu-title img {
  position: absolute;
  width: 200px;
  top: 50%;
  transform: translate(0%, -50%);
}

.menu-title-r {
  justify-content: right;
}

.menu-title-r img {
  left: 5%;
  opacity: 0.3;
}

.menu-title-l img {
  right: 5%;
  opacity: 0.3;
}

.menu-list {
  flex: 50%;
  display: flex;
  align-items: center;
  position: relative;
}

.menu-list img {
  display: none;
  position: absolute;
  width: 150px;
  top: 50%;
  transform: translate(0%, -50%);
}

.menu-list ul {
  list-style: none;
}

.menu-list li {
  color: #f9f9f9;
  font-size: 1.2rem;
  font-weight: 300;
  line-height: 1.5;
  cursor: pointer;
}

.menu-list li::before {
  content: "◼︎";
  color: #ffdf08;
  margin-right: 0.75rem;
}

.btm-r-border {
  position: absolute;
  bottom: -15%;
  right: 0;
  height: 3px;
  width: 80%;
  border-bottom: 1px dotted #ffdf08;
}

.btm-l-border {
  position: absolute;
  bottom: -15%;
  left: 0;
  height: 3px;
  width: 80%;
  border-bottom: 1px dotted #ffdf08;
}

.menu-modal-panel {
  background-color: #ffff;
  min-width: 300px;
  max-width: 90%;
  border-radius: 5px;
  animation: openModal 0.25s forwards ease-in;
  overflow: hidden;
}

.menu-modal-header {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  margin-bottom: 2.5rem;
  background-color: #ffdf08;
  padding: 1rem;
  position: relative;
  min-height: 90px;
}

.menu-modal-header h3 {
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 1.2rem;
  color: #142432;
  text-align: center;
  width: 100%;
  margin: 0 3rem 1rem 3rem;
}

.close-menu-modal {
  font-size: 1.3rem;
  color: #142432;
  cursor: pointer;
  transition: all 0.25s ease-in-out;
  position: absolute;
  top: 0.9rem;
  right: 1rem;
}

.close-menu-modal:hover {
  opacity: 0.5;
}

.menu-modal-icon {
  position: absolute;
  bottom: -20%;
  left: 50%;
  transform: translate(-50%, 20%);
  width: 80px;
  height: 80px;
  background-color: #142432;
  border-radius: 50%;
  border: 5px solid #f9f9f9;
  padding: 10px;
}

.menu-modal-icon img {
  width: 100%;
  height: auto;
}

.submenu-cntr {
  max-height: 80vh;
  overflow-y: scroll;
  padding: 0 1rem 1rem 1rem;
}

.submenu-cntr ul {
  list-style: none;
  line-height: 1.5;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 2rem;
  row-gap: 0.5rem;
}

.submenu-cntr li {
  display: flex;
  gap: 0.5rem;
}

.submenu-cntr p:first-child {
  color: #142432;
}

@media only screen and (max-width: 768px) {
  .menu-cntr {
    flex-direction: column;
    margin: 0 1rem 2rem 1rem;
    padding: 0 !important;
    min-height: 150px;
    display: block;
  }

  .menu-cntr:last-child {
    margin-bottom: 0 !important;
  }

  .resp-menu-img {
    display: block;
    position: absolute;
    width: 120px;
    top: 50%;
    right: 0;
    transform: translate(0, -50%);
    opacity: 0.4;
  }

  .menu-title-l,
  .menu-title-r {
    display: none;
  }

  .resp-title {
    display: block !important;
    font-size: 1.5rem;
    font-weight: 400;
    color: #ffdf08;
    text-transform: uppercase;
  }

  .menu-list {
    padding: 1rem 0;
  }

  .menu-list li {
    font-size: 1rem;
    line-height: 2;
  }

  .menu-title img {
    display: none;
  }

  .menu-list img {
    display: block;
    right: 0%;
    opacity: 0.3;
    width: 120px;
  }

  .btm-l-border,
  .btm-r-border {
    bottom: 0% !important;
    width: 100%;
  }

  .menu-modal-header {
    min-height: 110px;
  }

  .menu-modal-header h3 {
    margin: 0 1.2rem 1rem 1.2rem;
    font-size: 1.1rem;
  }

  .submenu-cntr {
    max-height: 60vh;
  }

  .submenu-cntr ul {
    font-size: 0.9rem;
    column-gap: 0.5rem;
  }
}
