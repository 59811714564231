body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

/* File: font.css */
@font-face {
  font-family: "AthenaRustic";
  src: local("AthenaRustic"), url("./fonts/Athena-Rustic.woff2") format("woff2");
}

@font-face {
  font-family: "AthenaRusticItalic";
  src: local("AthenaRusticItalic"), url("./fonts/AthenaRusticItalic-L3GZW.woff2") format("woff2");
}

@font-face {
  font-family: "BreakfastDistressed";
  src: local("BreakfastDistressed"), url("./fonts/Breakfast-Distrassed.woff2") format("woff2");
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background-color: #142432;
}

.main-cntr {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 15%;
  min-height: 100vh;
}

section {
  width: 100%;
  animation: pageLoadAnim 0.5s forwards ease-in;
}

@keyframes pageLoadAnim {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.default-loader {
  display: grid;
  place-items: center;
  animation: defaultLoaderAnim infinite 1.4s linear;
}

@keyframes defaultLoaderAnim {
  0% {
    opacity: 1;
    transform: scale(1);
    filter: saturate(1);
  }

  50% {
    opacity: 0.5;
    transform: scale(0.95);
    filter: saturate(0);
  }

  100% {
    opacity: 1;
    transform: scale(1);
    filter: saturate(2);
  }
}

.loader-img-cntr {
  background-color: white;
  border-radius: 50%;
  overflow: hidden;
  padding: 0.5rem;
}

.default-loader img {
  width: 150px;
  height: auto;
}

.modal-cntr {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  background-color: #000000b6;
  width: 100vw;
  height: 100vh;
  display: grid;
  place-items: center;
}

@keyframes openModal {
  0% {
    transform: scale(0.3);
    opacity: 0;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@media only screen and (max-width: 768px) {
  .main-cntr {
    margin: 0;
  }
}
