.contact-cntr {
  display: flex;
  padding: 2rem;
}

.contact-panel {
  flex: 50%;
  padding: 1rem;
  display: flex;
  flex-direction: column;
}

.contact-l {
  background-color: #e91f4f;
  justify-content: center;
}

.social-cta {
  display: flex;
  justify-content: right;
  gap: 3rem;
  width: 100%;
  margin-bottom: 1rem;
}

.social-cta img {
  width: 100%;
  height: auto;
}

.social-b-border {
  cursor: pointer;
  width: 30px;
  padding-bottom: 0.5rem;
  border-bottom: 2px solid #f9f9f9;
  display: grid;
  place-items: center;
  transition: all 0.25s ease-in-out;
}

.social-b-border:hover {
  filter: invert(0.3);
}

.map-img {
  max-width: 100%;
  cursor: pointer;
  transition: all 0.25s ease-in-out;
}

.map-img:hover {
  filter: brightness(0.7) blur(1px);
}

.contact-r {
  color: #f9f9f9;
}

.contact-r h1 {
  font-size: 5rem;
  font-family: AthenaRustic;
  letter-spacing: 3px;
  font-weight: 300;
}

.contact-info {
  line-height: 2;
}

.contact-info p {
  padding: 1rem 0;
}

.contact-info p:not(:last-child) {
  border-bottom: 0.5px solid #f9f9f92d;
}

@media only screen and (max-width: 768px) {
  .contact-cntr {
    flex-direction: column-reverse;
    padding: 0;
  }

  .contact-r h1 {
    font-size: 4rem;
  }
}
