.services-modal-cntr {
  background-color: #f9f9f9;
  min-height: 150px;
  min-width: 30%;
  border-radius: 5px;
  overflow: hidden;
  animation: openModal 0.25s forwards ease-in;
}

.close-menu-modal {
  text-align: right;
  opacity: 0.5;
  transition: all 0.25s ease-in-out;
}

.close-menu-modal:hover {
  opacity: 1;
}

.services-modal-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffdf08;
  padding: 1rem;
}

.services-modal-header h4 {
  color: #142432;
}

.services-modal-cntr ul {
  width: 100%;
  list-style: none;
  margin: 1rem 0;
  padding: 0 1rem;
}

.services-modal-cntr li {
  line-height: 2;
  font-size: 0.9rem;
}

.services-modal-cntr li::before {
  content: "◉";
  color: #142432;
  margin-right: 0.5rem;
}

.services-modal-chat {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
}

.services-modal-chat p {
  font-size: 0.85rem;
  font-style: italic;
  font-weight: 400;
  text-align: center;
}

.chat-service-btn {
  margin-left: 0.5rem;
  cursor: pointer;
  padding: 0.5rem 1rem;
  border-radius: 25px;
  border: 1px solid #4bc858;
  background-color: #4bc858;
  font-size: 1.05rem;
  color: #f9f9f9;
  position: relative;
  width: 150px;
  text-align: right;
  font-weight: 500;
  box-shadow: 1px 1px 5px 1px #00000045;
  transition: all 0.25s ease-in-out;
}

.chat-service-btn:hover {
  filter: brightness(0.85);
}

.chat-service-btn svg {
  width: auto;
  height: 45px;
  position: absolute;
  left: -1rem;
  top: 50%;
  transform: translateY(-50%);
}

@media only screen and (max-width: 768px) {
  .services-modal-cntr {
    width: 90%;
  }

  .services-modal-chat {
    flex-direction: column;
  }
}
