.test-cntr {
  display: flex;
  padding: 2rem;
  position: relative;
}

.test-panel {
  flex: 50%;
  padding: 1rem 0;
}

.test-r {
  background-color: #ffdf08;
}

.test-header {
  display: flex;
  gap: 2rem;
}

.resp-test-header {
  display: none;
}

.test-header img {
  width: 100px;
  height: auto;
}

.test-header h1 {
  font-size: 5rem;
  font-family: AthenaRustic;
  font-weight: 400;
}

.test-l .test-header {
  justify-content: right;
}

.test-r .test-header {
  justify-content: left;
}

.test-l .test-header h1 {
  -webkit-text-stroke: 0.5px #ffdf08;
}

.test-r .test-header h1 {
  color: #142432;
}

.user-cntr,
.user-msg-cntr {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.user-cntr {
  margin-top: 4rem;
}

.user-cntr img {
  width: 100px;
  margin-bottom: 0.5rem;
}

.user-cntr h2 {
  color: #f9f9f9;
}

.rating-cntr {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.rating-cntr svg:first-child,
.rating-cntr svg:last-child {
  max-width: 25px;
}

.rating-cntr svg:nth-child(2),
.rating-cntr svg:nth-child(4) {
  max-width: 45px;
}

.rating-cntr svg:nth-child(3) {
  max-width: 70px;
}

.no-rating {
  opacity: 0.2;
}

.rating-active {
  animation: activeRating 10s infinite linear;
}

@keyframes activeRating {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.user-msg-cntr {
  margin-top: 3.5rem;
}

.user-msg-cntr p {
  margin: 0;
  padding: 0 2rem;
  font-weight: 600;
  text-align: center;
  line-height: 1.5;
}

.test-toggle {
  position: absolute;
  bottom: 8%;
  right: 0;
  transform: translate(-5%, 8%);
  display: flex;
  justify-content: right;
  gap: 0.5rem;
  width: 100%;
}

.prev-test,
.next-test {
  color: #142432;
  font-size: 4rem;
  opacity: 0.3;
  cursor: pointer;
  transition: all 0.25s ease-in-out;
}

.prev-test:hover,
.next-test:hover {
  opacity: 1;
}

.disabled-toggle {
  pointer-events: none;
  cursor: default !important;
  opacity: 0.3 !important;
}

@media only screen and (max-width: 768px) {
  .test-cntr {
    flex-direction: column;
    padding: 0;
  }

  .resp-test-header {
    display: block;
  }

  .test-header {
    justify-content: center;
    gap: 0;
  }

  .test-header h1 {
    font-size: 3.5rem;
  }

  .test-header img {
    margin-right: 0.5rem;
    width: 60px;
  }

  .test-l {
    padding: 0;
  }

  .user-cntr {
    margin-top: 1rem;
  }

  .test-r .test-header {
    display: none;
  }

  .user-msg-cntr {
    margin-top: 0;
  }

  .test-toggle {
    bottom: 12%;
    left: 1.5rem;
    transform: translate(-7%, 12%);
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .prev-test,
  .next-test {
    font-size: 2.5rem;
  }
}
